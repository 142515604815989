import React, { FC } from 'react';
import {
  formatOpeningHours,
  formatPhoneNumber,
} from '@websites/utils';
import { IBusinessHours, ILocation } from '@websites/model';
import { ThemeContext } from '../ThemeContext';
import { getGoogleMapsIframeParameters } from './services/getGoogleMapsIframeParameters';

const OpeningHours: React.FC<{ openingHours: IBusinessHours[] }> = ({
  openingHours,
}) => {
  const formatedOpeningHours = formatOpeningHours(openingHours);
  if (!formatedOpeningHours) {
    return <></>;
  }
  return (
    <div>
      <div className="uppercase tracking-wide font-bold text-xs opacity-50">
        opening hours
      </div>
      <table>
        <tbody>
          {formatedOpeningHours.map((oh, ix) => (
            <tr key={ix}>
              <td>{oh.days}</td>
              <td className="pl-2">{oh.hours}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export const Locations: FC<{ locations: ILocation[] }> = ({ locations }) => {
  if (!locations) {
    return <> </>;
  }
  const locationsWithMaps = locations.filter((loc) => loc.hasMap);

  if (locationsWithMaps.length === 0) {
    return <> </>;
  }
  const [selectedLocation, setSelectedLocation] = React.useState(
    locationsWithMaps[0]
  );
  const { border, rounded } = React.useContext(ThemeContext);

  return (
    <div className="flex flex-col w-full space-y-5">
      <div
        className={`${rounded(
          'lg'
        )} overflow-hidden border border-white shadow-md h-108`}
      >
        <iframe
          //! TODO should this iframe be sandboxed?
          title="Google Maps"
          width="100%"
          height="100%"
          src={`https://www.google.com/maps/embed/v1/place?key=${
            process.env.GATSBY_WEBSITES_GOOGLE_MAPS_API_KEY
          }&q=${getGoogleMapsIframeParameters(selectedLocation)}`}
        ></iframe>
      </div>
      <div className="grid gap-4 grid-cols-1 md:grid-cols-2">
        {locationsWithMaps.map((loc: ILocation, ix: number) => (
          <button
            key={ix}
            onClick={() => setSelectedLocation(loc)}
            className={`bg-white p-8 shadow ${rounded(
              'lg'
            )} border-t-4 ${
              loc === selectedLocation
                ? ` ${border('highlight-dark')}`
                : `border-transparent hover:${border(
                    'highlight-dark'
                  )} hover:border-opacity-50`
            }`}
          >
            <div className="text-left space-y-4">
              {loc.address && (
                <div>
                  <div>
                    {loc.address.streetNumber && (
                      <span>{loc.address.streetNumber}&nbsp;</span>
                    )}
                    {loc.address.streetName && (
                      <span>{loc.address.streetName}</span>
                    )}
                  </div>
                  <div>
                    {loc.address.sublocality && (
                      <span>{loc.address.sublocality},&nbsp;</span>
                    )}
                  </div>
                  <div>
                    {loc.address.locality && (
                      <span>{loc.address.locality}</span>
                    )}
                    {loc.address.postalCode && (
                      <span>&nbsp;{loc.address.postalCode}</span>
                    )}
                  </div>
                </div>
              )}
              <div>
                {formatPhoneNumber(loc.telephone) && (
                  <div>
                    <div className="uppercase tracking-wide font-bold text-xs opacity-50">
                      phone
                    </div>
                    <div>{formatPhoneNumber(loc.telephone)}</div>
                  </div>
                )}
              </div>
              <div>
                {loc.openingHours && (
                  <OpeningHours openingHours={loc.openingHours} />
                )}
              </div>
            </div>
          </button>
        ))}
      </div>
    </div>
  );
};
