import React from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt as phoneIcon } from '@fortawesome/free-solid-svg-icons';

import {
  fireClickAnalyticsEvent, fireContactAnalyticsEvent, formattedDefaultPhoneNumber, slug,
} from '@websites/utils';
import { ThemeContext } from '@websites/components';

export const Menu = ({
  yellowBusiness,
  sections,
  className,
}) => {
  const formattedPhoneNumber = formattedDefaultPhoneNumber(yellowBusiness);
  const { text } = React.useContext(ThemeContext);

  return (
    <div
      className={`${className}
        w-full lg:container
        mx-auto
        text-xs md:text-lg ${text('base-light')}
        flex justify-between`}
    >
      <h1 className="font-bold">{yellowBusiness.name}</h1>
      <div className="flex items-center">
        <nav className="hidden md:flex items-center space-x-5 mr-5">
          {sections.map((sec) => (
            <Link
              className="text-base hover:underline"
              to={`#${slug(sec)}`}
              onClick={() => fireClickAnalyticsEvent('link_click', sec.name)}
              key={slug(sec)}
            >
              {sec.name}
            </Link>
          ))}
        </nav>
        {formattedPhoneNumber && (
          <a
            className="text-right lg:text-2xl lg:font-bold flex items-center space-x-2"
            rel="noopener"
            href={`tel:${formattedPhoneNumber.replace(' ', '')}`}
            onClick={() => {
              fireContactAnalyticsEvent('contact_link_clicked', 'phone');
            }}
          >
            <FontAwesomeIcon icon={phoneIcon} />
            <span>{formattedPhoneNumber}</span>
          </a>
        )}
      </div>
    </div>
  );
};
