import { IYellowBusiness, ISiteSection } from '@websites/model';
import React, { ReactNode } from 'react';
import { Footer, ThemeContext } from '@websites/components';
import Header from './Header';

const Page: React.FC<{
  business: IYellowBusiness;
  sections: ISiteSection[];
  children: ReactNode;
}> = ({ business, sections, children }) => {
  const { bg, text } = React.useContext(ThemeContext);
  return (
    <div
      className={`flex flex-col items-center min-h-screen min-w-full ${bg(
        'base-light',
      )} ${text('base-dark')} space-y-10 antialiased`}
    >
      <Header yellowBusiness={business} sections={sections} />

      <main className="flex-1 lg:container px-5 xl:px-0 flex flex-col space-y-10 lg:space-y-20">
        {children}
      </main>

      <Footer yellowBusiness={business} />
    </div>
  );
};

export default Page;
