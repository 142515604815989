import React, { FC } from 'react';
import {
  Locations,
  ThemeContext,
  ContactForm,
  Section,
  HTMLPageHeader,
  RichTextHTML,
  AlertBar,
} from '@websites/components';
import { IYellowBusiness, ISiteSection, Theme } from '@websites/model';
import { atLeastOneLocationHasMap, onContactFormSubmit } from '@websites/utils';
import ProductCollection from '../components/ProductCollection';
import Page from '../components/Page';

const YellowWebsite: FC<{
  yellowBusiness: IYellowBusiness;
}> = ({ yellowBusiness }) => {
  const servicesSection: ISiteSection | null = yellowBusiness.products?.length
    ? { name: 'Products & Services' }
    : null;
  const aboutBusinessSection: ISiteSection | null = yellowBusiness.description
    ? { name: 'About us' }
    : null;
  const locationSection: ISiteSection | null = yellowBusiness.locations?.length
    && atLeastOneLocationHasMap(yellowBusiness.locations)
    ? { name: 'Find us' }
    : null;
  const contactBusinessSection: ISiteSection | null = { name: 'Get in touch' };

  const sections = [
    servicesSection,
    aboutBusinessSection,
    locationSection,
    contactBusinessSection,
  ].filter(Boolean);

  const hasAlertBar = !!yellowBusiness.website?.alertBar?.text;

  return (
    <ThemeContext.Provider value={new Theme(yellowBusiness?.website.theme)}>
      {hasAlertBar && (<AlertBar alertBar={yellowBusiness?.website?.alertBar || null} />)}
      <Page
        business={yellowBusiness}
        // @ts-ignore
        sections={sections}
      >
        <HTMLPageHeader title="Home" business={yellowBusiness} />
        {aboutBusinessSection && (
          <Section section={aboutBusinessSection}>
            <RichTextHTML className="w-full text-md">
              {yellowBusiness.description}
            </RichTextHTML>
          </Section>
        )}
        {servicesSection && (
          <Section section={servicesSection}>
            {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
            <ProductCollection products={yellowBusiness.products!} />
          </Section>
        )}
        {locationSection && (
          <Section section={locationSection}>
            <Locations locations={yellowBusiness.locations} />
          </Section>
        )}
        <Section section={contactBusinessSection}>
          <ContactForm
            onSubmit={onContactFormSubmit}
          />
        </Section>
      </Page>
    </ThemeContext.Provider>
  );
};

export default YellowWebsite;
