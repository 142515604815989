import React from 'react'

import { ThemeContext } from '@websites/components';

export const Headlines = ({
  headline = '',
  subHeadline = '',
  className = '',
}) => {
  const { text } = React.useContext(ThemeContext);

  return (
    <div className={`${className} ${text('base-light')}`}>
      <div className={`w-full h-full flex flex-col items-center justify-center`}>
          <h1 className={`
            font-bold text-center
            text-xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-7xl`}>
          {headline}
          </h1>
          <h2 className={`
            text-center
            text-sm sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl`}>
          {subHeadline}
          </h2>
      </div>
    </div>
  )
}