import React, { FC } from 'react';

import { IAlertBar } from '@websites/model';
import { ThemeContext } from '@websites/components';

const AlertBar: FC<{
  alertBar: IAlertBar | null;
}> = ({ alertBar }) => {
  const { bg, text } = React.useContext(ThemeContext);
  return (
    alertBar?.link ? (
      <a href={alertBar?.link} className={`w-full ${bg('alert-bg')} flex justify-center`}>
        <div
          className={`lg:container flex justify-center px-3 lg:px-0 py-5 ${text('alert-text')}
          font-title tracking-normal text-md md:text-lg lg:text-xl
          text-center`}
        >
          {alertBar?.text}
        </div>
      </a>
    ) : (
      <div className={`w-full ${bg('alert-bg')} flex justify-center`}>
        <div
          className={`lg:container flex justify-center py-5 ${text('alert-text')}
          font-title tracking-normal text-md md:text-lg lg:text-xl
          text-center`}
        >
          {alertBar?.text}
        </div>
      </div>
    )
  );
};

export default AlertBar;
