import React, { FC } from 'react';
import { IYellowBusinessProduct } from '@websites/model';
import { RichTextHTML, ThemeContext, FluidImage } from '@websites/components';

const Product: FC<{ product: IYellowBusinessProduct }> = ({ product }) => {
  const { text, rounded } = React.useContext(ThemeContext);

  return (
    <div
      id={product.id}
      className={`bg-white flex flex-col ${rounded(
        'lg',
      )} shadow-md hover:shadow-lg overflow-hidden`}
    >
      <FluidImage
        fluid={product.gatsbyImageImage?.childImageSharp.fluid}
        src={product.image}
        alt={`Image for product named ${product.name}`}
        aspectRatio={4 / 3}
      />
      <div className="flex-1 p-6">
        {product.name && (
          <h2 className={`text-lg md:text-xl font-bold mb-2 ${text('base-dark')}`}>
            {product.name}
          </h2>
        )}
        {product.description && (
          <RichTextHTML className={`text-md md:text-md ${text('base-dark')}`}>
            {product.description}
          </RichTextHTML>
        )}
      </div>
    </div>
  );
};

export default Product;
