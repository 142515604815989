import React, { FC } from 'react';
import { Link } from 'gatsby';

export const LogoOrName: FC<{ businessLogo?: string, name?: string }> = ({
  businessLogo,
  name,
}) => (
  <>
    {businessLogo && (
      <Link to="/">
        <img
          src={businessLogo}
          className={`
            object-contain
            h-12 max-w-32
            md:h-16 md:max-w-48
            lg:h-20 lg:max-w-60
          `}
          alt=""
        />
      </Link>
    )}
    {!businessLogo && (
      <Link to="/" className="font-title font-bold text-sm md:text-xl">
        {name}
      </Link>
    )}
  </>
);
