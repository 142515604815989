export type ColorVariant =
  | 'accent-light'
  | 'accent-dark'
  | 'base-light'
  | 'base-dark'
  | 'title'
  | 'highlight-light'
  | 'highlight-dark'
  | 'alert-bg'
  | 'alert-text';

export class Theme {
  private _theme: string;

  constructor(theme: ThemeVariant) {
    this._theme = theme;
  }

  get theme() {
    return this._theme;
  }

  get isSharp() {
    return (
      this._theme === ThemeVariant.spring ||
      this._theme === ThemeVariant.candycane
    );
  }

  /**
   * Returns background color variant tailwind classname. E.g. if theme is 'rose' and variant argument is 20, this method will return the string `bg-rose-20`.
   * @param variant multiple of 10 between 10 and 60
   */
  bg = (variant: ColorVariant): string => {
    return `bg-${this._theme}-${variant}`;
  };

  /**
   * Returns text color variant tailwind classname. E.g. if theme is 'rose' and variant argument is 20, this method will return the string `text-rose-20`.
   * @param variant multiple of 10 between 10 and 60
   */
  text = (variant: ColorVariant): string => {
    return `text-${this._theme}-${variant}`;
  };

  /**
   * Returns border color variant tailwind classname. E.g. if theme is 'rose' and variant argument is 20, this method will return the string `border-rose-20`.
   * @param variant multiple of 10 between 10 and 60
   */
  border = (variant: ColorVariant): string => {
    return `border-${this._theme}-${variant}`;
  };

  rounded = (variant: 'xs' | 'sm' | 'md' | 'lg' | 'xl'): string => {
    return this.isSharp ? 'rounded-none' : `rounded-${variant}`;
  };
}

export enum ThemeVariant {
  rose = 'rose',
  pro = 'pro',
  tradie = 'tradie',
  fresh = 'fresh',
  spring = 'spring',
  candycane = 'candycane',
}
