import React, { FC } from 'react';

import { IYellowBusiness, ISiteSection } from '@websites/model';
import { ThemeContext, CoverImage, Headlines } from '@websites/components';

import { Menu } from './components/Menu';

const Header: FC<{
  yellowBusiness: IYellowBusiness;
  sections: ISiteSection[];
}> = ({ yellowBusiness, sections }) => {
  const { bg } = React.useContext(ThemeContext);

  return (
    <header
      className={`relative w-full h-0 ${bg('base-dark')} overflow-hidden p-5`}
      style={{
        paddingTop: '56.25%', // 16:9
      }}
    >
      <Menu
        className="absolute top-5 left-0 right-0 z-20"
        yellowBusiness={yellowBusiness}
        sections={sections}
      />
      <Headlines
        className="absolute inset-0 z-10"
        headline={yellowBusiness.website?.headline}
        subHeadline={yellowBusiness.website?.subHeadline}
      />
      <CoverImage
        className="absolute inset-0 z-0"
        yellowBusiness={yellowBusiness}
      />
    </header>
  );
};

export default Header;
