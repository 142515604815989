import React, { FC, ReactNode } from 'react';
import { ISiteSection } from '@websites/model';
import { ThemeContext } from '@websites/components';
import { slug } from '@websites/utils';

export const Section: FC<{ section: ISiteSection; children: ReactNode }> = ({
  section,
  children,
}) => {
  const theme = React.useContext(ThemeContext);
  const { border, text } = theme;

  return (
    <section
      className="flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-2 lg:container h-full w-full"
      id={slug(section)}
    >
      <div className="flex flex-col" style={{ flex: 1 }}>
        <div className={`w-20 ${border('highlight-dark')} border-b-4 mb-1`}></div>
        <h2
          className={`${text('title')} lg:pr-20 ${
            theme.isSharp
              ? 'text-md md:text-lg lg:text-xl font-title uppercase tracking-wider'
              : 'text-xl md:text-2xl lg:text-3xl font-bold'
          }`}
        >
          {section.name}
        </h2>
      </div>
      <div className="flex flex-wrap h-full" style={{ flex: 3 }}>
        {children}
      </div>
    </section>
  );
};
