import React from 'react'

import FluidImage from './FluidImage';

export const CoverImage = ({
  yellowBusiness,
  className,
}) => (
  <div className={className}>
    <FluidImage
      fluid={yellowBusiness.website?.gatsbyImageCoverImage?.childImageSharp?.fluid}
      src={yellowBusiness.website?.coverImage}
      alt={`Banner image for ${yellowBusiness.name}`}
      aspectRatio={16 / 9}
    />
    {(yellowBusiness.website.gatsbyImageCoverImage || yellowBusiness.website.coverImage) && (
      <div
        // cover image darkening overlay
        className="absolute left-0 top-0 bottom-0 right-0 w-full h-full bg-black"
        style={{ opacity: 0.4 }}
      ></div>
    )}
  </div>
)