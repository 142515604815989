import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { IYellowBusiness } from '@websites/model';
import { justTheText } from '@websites/utils';

export const HTMLPageHeader: FC<{
  title: string;
  business: IYellowBusiness;
  meta?: [];
  keywords?: string[];
}> = ({ title, business, meta = [], keywords = [] }) => {
  const getBusinessDescription = () =>
    business.description ? justTheText(business.description) : '';

  const getImageForSocialCards = () => {
    if (business.website.coverImage) {
      return business.website.coverImage;
    }
    return null;
  };

  const socialMetaTags = [
    {
      name: `description`,
      content: `${getBusinessDescription()}`,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      property: `og:title`,
      content: `${title} | ${business.name}`,
    },
    {
      property: `og:description`,
      content: `${getBusinessDescription()}`,
    },

    {
      property: 'og:url',
      content: `https://${process.env.GATSBY_WEBSITES_BUILD_URL}/`,
    },
    {
      name: `twitter:card`,
      content: `summary_large_image`,
    },
    {
      name: `twitter:creator`,
      content: business.name,
    },
    {
      name: `twitter:title`,
      content: `${title} | ${business.name}`,
    },
    {
      name: `twitter:description`,
      content: `${getBusinessDescription()}`,
    },
  ];

  const socialImage = getImageForSocialCards();
  if (socialImage) {
    socialMetaTags.push(
      ...[
        {
          name: `twitter:image`,
          content: socialImage,
        },
        {
          property: 'og:image',
          content: socialImage,
        },
      ]
    );
  }

  return (
    <Helmet
      htmlAttributes={{
        lang: 'en',
      }}
      title={title}
      titleTemplate={`%s | ${business.name}`}
      meta={socialMetaTags
        .concat(
          keywords?.length > 0
            ? [
                {
                  name: `keywords`,
                  content: keywords.join(`, `),
                },
              ]
            : []
        )
        .concat(meta || [])}
    >
      {business.website.jsonLD && (
        <script type="application/ld+json">{`${JSON.stringify(
          business.website.jsonLD
        )}`}</script>
      )}
    </Helmet>
  );
};
