import React, { useState, useEffect } from 'react';
import { parse } from 'query-string';
import { graphql, useStaticQuery } from 'gatsby';
import {
  IYellowBusiness,
  IServersideBusiness,
  ThemeVariant,
} from '@websites/model';
import { isClient, log, serverBusinessToClientBusiness } from '@websites/utils';
import YellowWebsite from '../scenes/YellowWebsite';

const businessQuery = graphql`
  query MyQuery {
    yellowBusiness: yellowBusiness {
      business {
        details {
          name
          description
          sameAs {
            name
            url
          }
          logo
        }
        providerConfiguration {
          # websites channel-specific details
          yellowWebsite {
            alertBar {
              link
              text
            }
            alternativeHeadline
            headline
            slug
            customDomain
            primaryImageOfPage
            gatsbyImagePrimaryImageOfPage {
              childImageSharp {
                fluid(maxWidth: 5120) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
        locations {
          address {
            administrativeArea
            locality
            postalCode
            streetName
            streetNumber
            sublocality
          }
          default
          email
          hasMap
          geo {
            latitude
            longitude
          }
          openingHours {
            opens
            closes
            dayOfWeek
            conditionsOfAccess
          }
          telephone {
            areaCode
            number
          }
        }
        products {
          name
          description
          image
          gatsbyImageImage {
            childImageSharp {
              fluid(maxWidth: 768) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
  }
`;

const IndexPage = () => {
  const queryResult = useStaticQuery(businessQuery);

  const serverBusiness: IServersideBusiness = queryResult.yellowBusiness.business;

  // eslint-disable-next-line no-restricted-globals
  const queryParams = isClient() ? parse(location.search) : {};
  const [
    previewDataFromIFrameParent,
    setPreviewDataFromIFrameParent,
  ] = useState<IServersideBusiness>();

  const clientBusiness: IYellowBusiness = serverBusinessToClientBusiness({
    serverBusiness,
    defaultTheme: ThemeVariant.tradie,
  });

  const [yellowBusiness, setYellowBusiness] = useState<IYellowBusiness>(
    clientBusiness,
  );

  useEffect(() => {
    if (
      yellowBusiness
      && queryParams?.theme
      && queryParams?.theme !== `${process.env.GATSBY_WEBSITES_THEME}`
    ) {
      const newBusiness = { ...yellowBusiness };
      newBusiness.website.theme = queryParams.theme as ThemeVariant;
      setYellowBusiness(newBusiness);
    }
  }, [queryParams]);

  useEffect(() => {
    if (yellowBusiness && previewDataFromIFrameParent) {
      const iframeBusinessAsClientBusiness = serverBusinessToClientBusiness({
        serverBusiness: previewDataFromIFrameParent,
        defaultTheme: ThemeVariant.tradie,
      });
      const newBusiness = {
        ...yellowBusiness,
        ...iframeBusinessAsClientBusiness,
      };
      setYellowBusiness(newBusiness);
    }
  }, [previewDataFromIFrameParent]);

  useEffect(() => {
    const eventHandler = (event) => {
      log('received message from iframe', event);
      if (event.isTrusted && event.data?.details) {
        log('message valid and will be applied', event.data);
        setPreviewDataFromIFrameParent(event.data);
      }
    };
    window.addEventListener('message', eventHandler);
    return () => {
      window.removeEventListener('message', eventHandler);
    };
  }, []);

  return (
    <div className="flex flex-col w-full h-full">
      {yellowBusiness && <YellowWebsite yellowBusiness={yellowBusiness} />}
    </div>
  );
};

export default IndexPage;
