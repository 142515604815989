import { IYellowBusinessProduct } from '@websites/model';
import React, { FC } from 'react';
import Product from './Product';

const ProductCollection: FC<{ products: IYellowBusinessProduct[] }> = ({
  products,
}) => (
  <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-8">
    {products?.map((prod) => (
      <Product product={prod} key={prod.id} />
    ))}
  </div>
);

export default ProductCollection;
